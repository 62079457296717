import type { NvrModel } from "@evercam/api/types"

export type Nvr = {
  id?: number
  deviceType: NvrDeviceType
  serial: string
  model: NvrModel
  httpUrl: string
  rtspUrl: string
  macAddress: string
  username: string
  password: string
  nerves: boolean
}

export enum NvrDeviceType {
  Rpi4 = "rpi4",
  Rpi5 = "rpi5",
  OrinNano = "orin_nano",
}

export enum NvrHddStatus {
  Error = "error",
  Idle = "idle",
  NotExist = "notexist",
  Ok = "ok",
  Unformatted = "unformatted",
}

export type NvrCreatePayload = {
  serial?: string
  httpUrl: string
  rtspUrl?: string
  deviceType?: NvrDeviceType
  model: NvrModel
  username: string
  password: string
  kitId?: number
}
