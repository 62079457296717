import type { DateTime } from "@evercam/api/types/time"
import { VideoWallPresetItemConfig } from "@evercam/api/types/videoWall"

export enum DelayUnit {
  Minute = "minute",
  Hour = "hour",
  Day = "day",
}

export enum CommonWidgetSettingsKeys {
  Width = "width",
  WidthResolution = "resolution",
  Height = "height",
  HeightResolution = "heightResolution",
}

export enum RecordingsWidgetSettingsKeys {
  DarkMode = "darkMode",
  Calendar = "calendar",
  HasDelay = "hasDelay",
  Delay = "delay",
  DelayUnit = "delayUnit",
  HasRange = "hasRange",
  DateRange = "dateRange",
  Width = CommonWidgetSettingsKeys.Width,
  WidthResolution = CommonWidgetSettingsKeys.WidthResolution,
  Height = CommonWidgetSettingsKeys.Height,
  HeightResolution = CommonWidgetSettingsKeys.HeightResolution,
}

export enum LiveViewWidgetSettingsKeys {
  Fullscreen = "fullscreen",
  Download = "download",
  Zoom = "zoom",
  Playpause = "playpause",
  Refresh = "refresh",
  Width = CommonWidgetSettingsKeys.Width,
  WidthResolution = CommonWidgetSettingsKeys.WidthResolution,
  Height = CommonWidgetSettingsKeys.Height,
  HeightResolution = CommonWidgetSettingsKeys.HeightResolution,
}

export enum BimCompareWidgetSettingsKeys {
  Timeline = "timeline",
  Calendar = "calendar",
  DarkMode = "darkMode",
  Width = CommonWidgetSettingsKeys.Width,
  WidthResolution = CommonWidgetSettingsKeys.WidthResolution,
  Height = CommonWidgetSettingsKeys.Height,
  HeightResolution = CommonWidgetSettingsKeys.HeightResolution,
}

export enum VideoWallWidgetSettingsKeys {
  Preset = "preset",
  RefreshRate = "refreshRate",
  Width = CommonWidgetSettingsKeys.Width,
  WidthResolution = CommonWidgetSettingsKeys.WidthResolution,
  Height = CommonWidgetSettingsKeys.Height,
  HeightResolution = CommonWidgetSettingsKeys.HeightResolution,
}

export type CommonWidgetSettings = {
  width: number
  resolution: string
  height: number
  heightResolution: string
}

export type RecordingsWidgetSettings = CommonWidgetSettings & {
  darkMode: boolean
  calendar: boolean
  hasDelay: boolean
  delay: number
  delayUnit: DelayUnit
}

export type LiveViewWidgetSettings = CommonWidgetSettings & {
  fullscreen: boolean
  download: boolean
  zoom: boolean
  playpause: boolean
  refresh: number
}

export type BimCompareWidgetSettings = CommonWidgetSettings & {
  timeline: boolean
  darkMode: boolean
}

export type VideoWallWidgetSettings = CommonWidgetSettings & {
  preset: VideoWallPresetItemConfig
  refreshRate: number
}

export type WidgetSettings =
  | RecordingsWidgetSettings
  | LiveViewWidgetSettings
  | BimCompareWidgetSettings
  | VideoWallWidgetSettings

export enum WidgetType {
  LiveWidget = "Live View",
  Recording = "Recordings",
  BimCompare = "BIM Compare",
  VideoWall = "Video Wall",
}

export const RessourceByType = {
  camera: [WidgetType.LiveWidget, WidgetType.Recording, WidgetType.BimCompare],
  project: [WidgetType.VideoWall],
}

export type WidgetsListRequestPayload = {
  userId: number
  projectId: number
  cameraId: number
  active: boolean
  settings: WidgetSettings
  widgetType: WidgetType
  isPublic: boolean
  allowedOrigin: string
}

export type Widget = {
  id: number
  ressources: {
    camera?: {
      id: number
      exid: string
      name: string
    }
    project?: {
      id: number
      exid: string
      name: string
    }
  }
  widgetType: WidgetType
  settings: WidgetSettings
  allowedOrigin: string
  isPublic: boolean
  active: boolean
  user: {
    id: number
    email: string
  }
  insertedAt: DateTime
  updatedAt: DateTime
}

export type WidgetFull = {
  id: number
  ressources: {
    camera?: {
      id: number
      exid: string
      name: string
    }
    project?: {
      id: number
      exid: string
      name: string
    }
  }
  widgetType: WidgetType
  widgetKey: string
  widgetId: string
  active: boolean
  user: {
    id: number
    email: string
  }
  settings: WidgetSettings
  sessionSettings: any
  allowedOrigin: string
  isPublic: boolean
  insertedAt: DateTime
  updatedAt: DateTime
}

export type WidgetPayload = {
  ressources: {
    cameraId?: number
    projectId?: number
  }
  widgetType: WidgetType
  active: boolean
  settings: WidgetSettings
  isPublic: boolean
  allowedOrigin: string
}
